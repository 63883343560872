import React, { useCallback, useRef, useState } from "react";
import { Asset } from "models/schemas/asset";
import { Box, Button, Card, CardContent, Slide } from "@mui/material";
import { FileDownloadRounded } from "@mui/icons-material";

export type AssetCard = {
    asset: Asset;
}

const AssetCard: React.FC<AssetCard> = ({ asset }) => {

    const [showButton, setShowButton] = useState<number>(0)
    
    const cardRef = useRef(null);
    
    const onMouseEnter = useCallback(() => {
        setShowButton(prevState => prevState + 1)
    }, [])

    const onMouseLeave = useCallback(() => {
        setShowButton(prevState => prevState - 1)
    }, [])
    
    const downloadAsset = useCallback(() => {
        window.open(`${asset.url}?dl=true`)
    }, [])
    
    return (
        <Card ref={cardRef} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} sx={{position: "relative"}} variant={"outlined"}>
            <img src={asset.url} alt={asset.alt} width={"100%"} height={"100%"} style={{display: "flex"}}/>
            <Box position={"absolute"} left={"0.5em"} bottom={"0.5em"}>
                <Slide in={showButton > 0} direction={"up"} container={cardRef.current}>
                    <Button variant={"contained"} color={"info"} size={"small"} endIcon={<FileDownloadRounded/>} onClick={downloadAsset}>Download Asset</Button>
                </Slide>
            </Box>
        </Card>
    )

}
export { AssetCard }