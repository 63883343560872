import React, { useCallback } from "react";

import { Box, Button, Container, Grid } from "@mui/material";
import { SectionHeader } from "components/layout/section-header";
import { Layout } from "layouts/layout";
import { graphql, HeadFC, HeadProps, PageProps } from "gatsby";
import { Content } from "models/schemas/content";
import { SectionContent } from "components/layout/section-content";
import { FileDownloadRounded } from "@mui/icons-material";
import { Asset } from "models/schemas/asset";
import { PageMetadata } from "components/layout/page-metadata";
import { Page } from "models/schemas/page";

export type BrandProps = {
    page: Page,
    asset: Asset
}

const Brand: React.FC<PageProps<BrandProps>> = ({ data }) => {

    const { page, asset } = data;
    
    const downloadAsset = useCallback(() => {
        window.open(`${asset.url}?dl=true`)
    }, [asset])

    return (
        <Layout>
            <Container maxWidth={'md'}>
                <Grid container spacing={10}>
                    <Grid item container mt={"5.0em"}>
                        <SectionHeader
                            title={"Brand Guidelines"}
                            description={"Everything you need to reference the Stackly brand."}
                        />
                    </Grid>
                    <Grid item container spacing={8}>
                        {page.contents.map((content: Content, index: number) => (
                            <SectionContent content={content} key={`section-${index}`}/>
                        ))}
                    </Grid>
                    <Grid item container alignItems={"center"} justifyContent={"center"}>
                        <Grid item position={"relative"}>
                            <Box className={"radial-gradient"} width={"90.0vw"} height={500}/>
                            <Button size={"large"} variant={"contained"} color={"info"} startIcon={<FileDownloadRounded/>} onClick={downloadAsset}>Download Brand Assets</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    )
}
export default Brand;

export const Head: HeadFC = (props: HeadProps<any>) => (
    <PageMetadata location={props.location.pathname} metadata={props.data.page.metadata}/>
)

export const query = graphql`
{
    asset: graphCmsAsset(identifier: { eq: "brand-assets"}) {
        url
        alt
    }
    page: graphCmsPage(identifier: { eq: "brand" }) {
        metadata {
            title
            description
            thumbnail {
              url
            }
            embedImage {
              url
            }
            jsonLD
        }
        banner {
            url
            alt
        }
        contents {
            heading
            richText {
              markdown
            }
            images {
              alt
              url
              reference {
                alt
                url
              }
            }
            colours {
                hex
                rgba { 
                    r
                    g
                    b
                }
            }
        }
    }
}
`
