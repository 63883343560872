import React, { useCallback, useEffect, useRef, useState } from "react";
import { Asset } from "models/schemas/asset";
import { Box, Button, Card, CardContent, Grid, IconButton, Paper, Slide, Typography, useTheme } from "@mui/material";
import { ContentCopyRounded, FileDownloadRounded } from "@mui/icons-material";
import { Colour } from "models/schemas/colour";
import { calculateBrightness } from "helpers/colourHelper";
import SnackbarUtils from "components/notifications/snackbar";

export type ColourCard = {
    colour: Colour;
}

const ColourCard: React.FC<ColourCard> = ({ colour }) => {

    const [contrastColour, setContractColour] = useState<string>("");
    
    useEffect(() => {
        setContractColour(
            calculateBrightness(colour) > 128 ? "rgba(0,0,0,0.75)" : "rgba(255,255,255,0.75)"
        )
    }, [colour])

    const copyToClipboard = useCallback( (text: string) => () => {
        navigator.clipboard.writeText(text);
        SnackbarUtils.toast(`${text} copied to your clipboard.`)
    }, [])
    
    const displayText = useCallback((text: string) => (
        <Grid container item spacing={1} justifyContent={"center"} alignItems={"center"}>
            <Grid item>
                <Typography 
                    color={contrastColour}
                    variant={"body2"} 
                    fontWeight={"bold"}
                >{text}</Typography>
            </Grid>
            <Grid item>
                <IconButton size={"large"} sx={{color: contrastColour }} onClick={copyToClipboard(text)}>
                    <ContentCopyRounded fontSize={"small"} sx={{color: contrastColour }}/>
                </IconButton>
            </Grid>
        </Grid>
    ), [contrastColour])

    return (
        <Card sx={{backgroundColor: colour.hex, pt: "5.0em", pb: "5.0em"}} variant={"outlined"}>
            <Grid container>
                {displayText(colour.hex.toUpperCase())}
                {displayText(`RGB (${colour.rgba.r}, ${colour.rgba.g}, ${colour.rgba.b})`)}
            </Grid>
        </Card>
    )

}
export { ColourCard }