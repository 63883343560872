import React from 'react';
import { Grid, Typography } from "@mui/material";
import { Content } from "models/schemas/content";
import { AssetCard } from "components/cards/asset-card";
import { ColourCard } from "components/cards/colour-card";
import Markdown from "markdown-to-jsx";
import { markdownOptions } from "components/options/markdown-options";

export type SectionContentProps = {
    content: Content;
}

const SectionContent: React.FC<SectionContentProps> = ({ content }) => {
    
    const { heading, richText, images, colours } = content;
    
    return (
        <Grid container item spacing={2}>
            <Grid container item spacing={1}>
                {heading &&
                    <Grid item>
                        <Typography variant={"h5"} color={"textPrimary"}>{heading}</Typography>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Markdown options={markdownOptions}>{richText.markdown}</Markdown>
                </Grid>
            </Grid>
            <Grid item container spacing={2}>
                { images.map(image => (
                    <Grid key={image.url} item xs={12} sm>
                        <AssetCard asset={image}/>
                    </Grid>
                ))}
            </Grid>
            <Grid item container spacing={2}>
                { colours.map(colour => (
                    <Grid key={colour.hex} item xs={12} sm>
                        <ColourCard colour={colour}/>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}
export { SectionContent }